










import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component
export default class UserPrivacyGuardWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'UserPrivacyGuardWidgetStore';

  @Prop({ required: false, default: false })
  private readonly isPrivate!: boolean;

  created(): void {
    this.setDataConfig();
  }
}
